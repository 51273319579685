<script>
import ReportBug from '@/components/ReportBug';
import modal from '@/components/Modal';

export default {
  name: 'AuthLayout',
  components: { modal, ReportBug },
  data() {
    return {
      helpVisible: false,
    };
  },
  methods: {
    toggleHelp() {
      this.helpVisible = !this.helpVisible;
    },
    goToHelpCentre() {
      this.helpVisible = false;
      this.$router.push({ name: 'HelpCentre' });
    },
  },
};
</script>

<template>
  <div>
    <modal v-if="helpVisible" :show-buttons="false" @proceed="toggleHelp">
      <template slot="header">Having trouble?</template>
      <p class="mt-0">
        If you are not sure of the process to complete your onboarding or what will happen next, we have a
        <a href="javascript:void(0);" @click="goToHelpCentre">Help Centre</a> where you can find useful information and
        instructional videos showing how to use the {{ $t('site.websiteTitle') }} Portal?
      </p>
      <p>If you're still having trouble feel free to get in touch via either email or phone using the details below</p>
      <p><i class="icon-phone mr-2"></i>Email at {{ $t('site.supportEmail') }}</p>
      <p><i class="icon-mail-read mr-2"></i>Phone on {{ $t('site.supportPhoneNumber') }}</p>
    </modal>
    <!-- Main navbar -->
    <div id="top-nav" class="navbar bg-info-300 fixed-top">
      <div class="navbar-brand wmin-0 mr-5">
        <a class="d-inline-block" href="/">
          <img :src="require('@/assets/' + $t('site.logo'))" alt="" />
        </a>
      </div>
    </div>

    <!-- /main navbar -->
    <b-navbar toggleable="sm">
      <b-navbar-toggle class="p-2 border-0 w-100" target="nav-collapse"
        ><i class="icon icon-menu7 mr-2" />Menu
      </b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <ul class="nav navbar-nav">
          <li class="nav-item">
            <router-link :to="{ name: 'Dashboard' }" active-class="font-weight-bold" class="navbar-nav-link">
              <i class="icon-home mr-2"></i> Dashboard
            </router-link>
          </li>
        </ul>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <span class="navbar-nav-link ml-sm-auto" @click="toggleHelp">
            <i class="icon-phone2 mr-2 icon"></i>
            <span class="d-sm-none d-md-inline">Require Assistance?</span>
          </span>

          <report-bug>
            <span class="navbar-nav-link ml-sm-auto">
              <i class="icon-spam mr-2 icon"></i>
              <span class="d-sm-none d-md-inline">Report Problem</span>
            </span>
          </report-bug>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <!-- Page content -->
    <div class="page-content mt-3">
      <!-- Main content -->
      <div class="content-wrapper">
        <!-- Content area -->
        <div class="content d-flex justify-content-center align-items-center">
          <!-- Password recovery form -->
          <div class="card mb-0 col-lg-5">
            <div class="card-body">
              <transition mode="out-in" name="fade">
                <router-view />
              </transition>
            </div>
          </div>
          <!-- /password recovery form -->
        </div>
        <!-- /content area -->
      </div>
    </div>
    <!-- Footer -->
    <div class="navbar navbar-expand-lg navbar-light fixed-bottom">
      <div class="text-center d-lg-none w-100">
        <report-bug>
          <div class="m-1">
            <i class="icon-spam mr-2 icon cursor-pointer"></i>
            <span class="d-md-inline">Report Problem</span>
          </div>
        </report-bug>
      </div>

      <div id="navbar-footer" class="navbar-collapse collapse">
        <span class="navbar-text">
          &copy; 2020 - {{ new Date().getFullYear() }} <a href="#">{{ $t('site.companyTitle') }} - Client Platform</a>
        </span>

        <ul class="navbar-nav ml-lg-auto">
          <li class="nav-item">
            <a :href="$t('site.relatedSiteUrl')" class="navbar-nav-link font-weight-semibold">
              <span>
                <i class="icon-earth mr-2"></i>
                {{ $t('site.websiteTitle') }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- /footer -->
  </div>
</template>
